import React from 'react'
//images
import Logo from '../Assets/PLdev.png';
import { Link } from 'react-router-dom';

export default function Header() {
  
    return (
      <header className='py-2'>
        <div className='container mx-auto'>
          <div className='flex justify-between items-center'>
            <img src = {Logo} className='max-w-[70px]'/>
            {/* <p className='font-bold text-lg text-center'>.dev</p> */}

            <Link
              to="/contact"
              className="cursor-pointer btn bt-sm">
               <button className='btn btn-sm'>Contact Me</button> 
               </Link>

          </div>
        </div>
      </header>
    )
}
