import React from 'react';
import './index.css';
import About from './components/About.jsx';
import Banner from './components/Banner.jsx';
import Contact from './components/Contact.jsx';
import Header from './components/Header.jsx';
import Nav from './components/Nav.jsx';
import Work from './components/Work.jsx';
import Footer from './components/Footer.jsx'
import Success from './components/Success.jsx'; 
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className='bg-gradient-to-b from-blue-900 to-blue-400 bg-site bg-no-repeat bg-cover overflow-hidden '>
      <BrowserRouter>
        <Header/>
        <Nav />
        <Routes>
          <Route index path="/" element={<Banner />} />
          <Route path="/about" element={<About />} />
          <Route path="/work" element={<Work />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contactSubmitted" element={<Success />} />
          {/* Route for thank you for submitting /contactSubmitted  */}
        </Routes>
        <Footer  />
      </BrowserRouter>
    </div>
  );
}

export default App;
