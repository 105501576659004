import React from 'react';
import { NavLink } from 'react-router-dom';
import { BiHomeAlt, BiUser } from 'react-icons/bi';
import { BsBriefcase, BsChatSquare } from 'react-icons/bs';

export default function Nav() {
  return (
    <nav className="fixed bottom-2 lg:bottom-8 w-full overflow-hidden z-50 ">
      <div className="container mx-auto">
        <div className="w-full bg-white/20 h-[96px] backdrop-blur-2xl rounded-full max-w-[460px] mx-auto px-5 flex justify-between items-center text-2x1 text-white  ">
          <NavLink
            exact
            to="/"
            activeClassName="active"
            className="cursor-pointer w-[60px] h-[60px] flex items-center justify-center"
          >
            <BiHomeAlt />
          </NavLink>
          <NavLink
            to="/about"
            activeClassName="active"
            className="cursor-pointer w-[60px] h-[60px] flex items-center justify-center"
          >
            <BiUser />
          </NavLink>
          <NavLink
            to="/work"
            activeClassName="active"
            className="cursor-pointer w-[60px] h-[60px] flex items-center justify-center"
          >
            <BsBriefcase />
          </NavLink>
          <NavLink
            to="/contact"
            activeClassName="active"
            className="cursor-pointer w-[60px] h-[60px] flex items-center justify-center"
          >
            <BsChatSquare />
          </NavLink>
        </div>
      </div>
    </nav>
  );
};