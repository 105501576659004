import React from 'react'
import { useState } from 'react'

const MODAL_STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#90cdf4',
  height: '80%',
  width: '89%',
  zIndex: 1000,
  borderRadius: '30px',
  overflow: 'hidden'
}

const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgb(0, 0, 0, 0.3)',
  zIndex: 1000
}
const descriptionStyle = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  padding: '8px',
  borderRadius: '4px',
};


export default function Popup({ title, content, image, description, linkVideo }) {
  const [isOpen, setIsOpen] = useState(false);
  const [showControls, setShowControls] = useState(false); // State for showing/hiding video controls
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const buttonStyle = {
    backgroundImage: `url(${image})`,
    position: 'relative', // Ensures positioning context for the text
    overflow: 'hidden', // Prevents text overflow

  };

  if (isOpen === true) return (

    <>
      <div style={OVERLAY_STYLES}>
        <div style={MODAL_STYLES} className='flex-col justify-center items-center px-4 space-x-2 space-y-2'>
          <button className='btn-sm bg-red-600 rounded-lg fixed top-4 right-4' onClick={closeModal}>X</button>
          <h2 className='text-3xl pb-4'>{title}</h2>
          <div className='flex flex-col lg:flex-row items-center justify-center gap-32'>
            {content}
            {/* Responsive Video Container */}
            <div className='w-full lg:w-2/3'>
              <video
                className='w-full h-auto rounded-md'
                src={linkVideo}
                controls={showControls} // Show controls when state is true
                onMouseEnter={() => setShowControls(true)} // Show controls on hover (desktop)
                onMouseLeave={() => setShowControls(false)} // Hide controls when not hovering
                onTouchStart={() => setShowControls(true)} // Show controls on touch (mobile)
                onTouchEnd={() => setShowControls(false)} // Hide controls when touch ends
              autoPlay
              ></video>
            </div>

          </div>
        </div>
      </div>
      <button className='lg:px-60 px-28 py-20 bg-cover bg-center rounded-lg'
        style={buttonStyle}
        onClick={openModal}></button>
    </>

  )
  return (
    <div className='hover:bg-[#FF56F6] py-2 px-2 rounded-lg'>
      <button className="lg:px-60 px-40 py-20 bg-cover bg-center flex justify-start rounded-lg shadow-2xl"
        style={buttonStyle}
        onClick={openModal}>
        <p className='absolute bottom-0 right-0 bg-black bg-opacity-50 text-white px-4 py-2 rounded-bl-md'>
          {description}
        </p>
      </button>

    </div>

  )
}
