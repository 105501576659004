import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import { TypeAnimation } from 'react-type-animation';

export default function Success() {
  return (
    <section className="min-h-screen" id="success">
      <div className="container mx-auto py-16">
        <div className="flex flex-col lg:flex-row">
          {/* text */}
          <div className="flex-1 flex justify-center lg:justify-start items-center">
            <motion.div
              variants={fadeIn('right', 0.3)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
            >
              <h4 className="text-lg lg:text-xl font-medium mb-2 tracking-wide">
                Thank you for reaching out!
              </h4>
              <h2 className="text-[40px] lg:text-[80px] leading-none mb-8 lg:mb-12">
                <TypeAnimation
                  sequence={[
                    'Your message has been sent',
                    1250,
                    'Thank you,',
                    750,
                    'we\'ll be in touch soon!',
                    750,
                  ]}
                  speed={10}
                  className="theColorOfText"
                  wrapper="span"
                  repeat={Infinity}
                />
              </h2>
            </motion.div>
          </div>
          {/* illustration or additional content */}
          <motion.div
            variants={fadeIn('left', 0.2)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex justify-center items-center"
          >
            <div className="w-full flex justify-center">
              <svg
                className="w-32 h-32 text-[#7597de]"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
