import React, { Component } from 'react'
import {
  FaHtml5,
  FaCss3Alt,
  FaJsSquare,
  FaReact,
  FaCuttlefish,
  FaDatabase, 
  FaGitAlt,
  FaPython,
  FaJava
} from 'react-icons/fa'
import { SiAdobeillustrator, SiMicrosoftoffice, SiVite } from 'react-icons/si'
import CountUp from 'react-countup'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import { fadeIn } from '../variants.js'
import Image from '../Assets/TIMELINE.png'
import Marquee from 'react-fast-marquee'


const About = () => {
  // Component logic and state can be defined here, here the hook i learned about lol
  const [ref, inView] = useInView({
    threshold: 0.5,
  })
  return (
    <section className="min-h-screen" id="about">
      <div className="container mx-auto">
        <div className="flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-5 lg:gap-y-0">
          {/* TimeLine */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className="hidden lg:flex justify-center flex-1 max-w-xs lg:max-w-xl bg-contain bg-no-repeat bg-top"
          >
            <img src={Image}></img>
          </motion.div>
          {/* TimeLine */}

          {/* Text */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1"
          >
            <h2 className="h2 theColorOfText">About me</h2>
            <h3 className="h3 mb-4">
              I am a 3rd year Computer Science student @ the University of Florida
            </h3>
            <p className="mb-6">
              It was popularised in the 1960s with the release of Letraset
              sheets containing Lorem Ipsum passages, and more recently with
            </p>
            <div>
              {/* Grad */}
              <div className="flex" ref={ref}>
                <div>
                  <div className="font-primary text-sm tracking-[2px] font-semibold">
                    Graduation Year:{' '}
                  </div>
                  <div className="text-[30px] font-primary text-gradient mb-2 lg:text-gradient text-white">
                    {inView ? (
                      <CountUp
                        separator=""
                        start={1950}
                        end={2026}
                        duration={4}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              {/* Grad */}

              {/* skills */}
              <div className=" flex justify-start">
                <Marquee
                  pauseOnHover={true}
                  speed={10}
                  direction={'right'}
                  className="flex flex-row justify-start items-start max-w-[500px] lg:max-w-[600px] lg:flex-col lg:text-[60px] text-[50px] rounded-lg shadow-xl"
                >
                  <p className='py-2'>
                    <FaCuttlefish />
                  </p>
                  <p className='py-2'>
                    <FaHtml5 />
                  </p>
                  <p className='py-2'>
                    <FaCss3Alt />
                  </p>
                  <p className='py-2'>
                    <FaJsSquare />
                  </p>
                  <p className='py-2'>
                    <FaPython/>
                  </p>
                  <p className='py-2'>
                    <FaJava/>
                  </p>
                  <p className='py-2'>
                    <FaReact />
                  </p>
                  <p className='py-2'>
                    <FaDatabase />
                  </p>
                  <p className='py-2'>
                    <FaGitAlt />
                  </p>
                  <p className='py-2'>
                    <SiVite />
                  </p>
                  <p className='py-2'>
                    <SiAdobeillustrator />
                  </p>
                  <p className='py-2'>
                    <SiMicrosoftoffice />
                  </p>
                </Marquee>
              </div>
              {/* skills */}
            </div>
          </motion.div>
          {/* Text */}
        </div>
      </div>
    </section>
  )
}

export default About
